<template>
  <div>
    <breadcrumbs title="news"/>

    <!-- CONTENT -->
    <div class="section">
      <div class="container">
        <div class="row">
          <div  v-for="(item, index) in data" :key="index" class="col-sm-6 col-md-4">
            <!-- BOX 1 -->
            <div class="box-news-1">
              <div class="media gbr">
                <img :src="item.image_path != null ? item.image_path : `https://via.placeholder.com/600x400`"
                     @error="$event.target.src='https://via.placeholder.com/600x400'"
                     class="img-responsive"
                >
              </div>
              <div class="body">
                <div class="title"><a href="news-detail.html" title="">{{item.description}}</a></div>
                <div class="meta">
                  <span class="date"><i class="fa fa-clock-o"></i> {{ item.publish_date | date }}</span>
<!--                  <span class="comments"><i class="fa fa-comment-o"></i> 0 Comments</span>-->
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row page-button">
          <div class="col-sm-12 col-md-12 px-0">
            <ul class="pagination mx-0">
              <li :key="page" @click="pageClick(page)" v-bind:class="{ active: page == meta.current_page }"
                  v-for="page in meta.last_page"><a>{{ page }}</a></li>
            </ul>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "News",
  components: {
    breadcrumbs: () => import('@/components/layout/Breadcrumb'),
  },
  data() {
    return {
      isLoading: true,
      data: null,
      links: null,
      meta: null,
      page: 1,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getData(this.page)
    },
    getData(page) {
      this.isLoading = true;
      this.$axios.get(`categories/recent-news/articles`, {params: {page: page}}).then(res => {
        res.data.data;
        const response = res.data;
        this.data = response.data;
        this.links = response.links;
        this.meta = response.meta;
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.isLoading = false;
        this.scrollToTop();
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    pageClick(page) {
      this.getData(page)
    }
  },
}
</script>

<style scoped>
</style>